<template>
<q-card
  v-if="loaded"
  :id="`oculist_history_${value.id}`"
  class="q-ma-md"
  :class="{ changed: changed, locked: locked }"
  v-observe-visibility="{
    callback: visibilityChanged,
    intersection: {
      rootMargin: '10000px 0px 10000px 0px'
    }
  }"
  >
  <q-card-section v-if="visible">
    <div class="row">
  <div class="col-xs-12 col-sm-12">
  <string-field
    v-model="value.terapie_praticate"
    :prevValue="prevValue.terapie_praticate"
    :disable="locked"
    label="Visite precedenti e terapie oculari praticate"
    fieldName="terapie_praticate"
    subtype="textarea" />
</div>

</div>
<div class="row">
  <div class="col-xs-12 col-sm-6">
  <string-field
    v-model="value.familiarity"
    :prevValue="prevValue.familiarity"
    :disable="locked"
    label="Familiarità per malattie oculari"
    fieldName="familiarity"
    subtype="textarea" />
</div>
<div class="col-xs-12 col-sm-6">
  <string-field
    v-model="value.last_visit"
    :prevValue="prevValue.last_visit"
    :disable="locked"
    label="Ultima Visita Oculistica"
    fieldName="last_visit" />
</div>

</div>
<div class="row">
  <div class="col-xs-12 col-sm-12">
  <string-field
    v-model="value.consultancy_reason"
    :prevValue="prevValue.consultancy_reason"
    :disable="locked"
    label="Motivo Visita"
    fieldName="consultancy_reason"
    subtype="textarea" />
</div>

</div>
<div class="row">
  <div class="col-xs-12 col-sm-6">
  <lenti-field
    v-model="value.lenti_pl_od"
    :prevValue="prevValue.lenti_pl_od"
    :disable="locked"
    label="Lenti P.L. OD"
    fieldName="lenti_pl_od" />
</div>
<div class="col-xs-12 col-sm-6">
  <lenti-field
    v-model="value.lenti_pl_os"
    :prevValue="prevValue.lenti_pl_os"
    :disable="locked"
    label="Lenti P.L. OS"
    fieldName="lenti_pl_os" />
</div>

</div>
<div class="row">
  <div class="col-xs-12 col-sm-6">
  <string-field
    v-model="value.addiction_od"
    :prevValue="prevValue.addiction_od"
    @input="addChanged('od', $event)"
    :disable="locked"
    label="Add"
    fieldName="addiction_od" />
</div>
<div class="col-xs-12 col-sm-6">
  <string-field
    v-model="value.addiction_os"
    :prevValue="prevValue.addiction_os"
    @input="addChanged('os', $event)"
    :disable="locked"
    label="Add"
    fieldName="addiction_os" />
</div>

</div>
<div class="row">
  <div class="col-xs-12 col-sm-6">
  <lenti-field
    v-model="value.lenti_pv_od"
    :prevValue="prevValue.lenti_pv_od"
    :disable="locked"
    label="Lenti P.V. OD"
    fieldName="lenti_pv_od" />
</div>
<div class="col-xs-12 col-sm-6">
  <lenti-field
    v-model="value.lenti_pv_os"
    :prevValue="prevValue.lenti_pv_os"
    :disable="locked"
    label="Lenti P.V. OS"
    fieldName="lenti_pv_os" />
</div>

</div>
<div class="row">
  <div class="col-xs-12 col-sm-6">
  <integer-field
    v-model="value.years"
    :prevValue="prevValue.years"
    :disable="locked"
    label="Da che anno"
    fieldName="years" />
</div>
<div class="col-xs-12 col-sm-6">
  <yes-or-no
    v-model="value.lens_ok"
    :prevValue="prevValue.lens_ok"
    :disable="locked"
    label="Si trova bene"
    fieldName="lens_ok" />
</div>

</div>
<div class="row">
  <div class="col-xs-12 col-sm-12">
  <string-field
    v-model="value.notes"
    :prevValue="prevValue.notes"
    :disable="locked"
    label="Note"
    fieldName="notes" />
</div>

</div>

  </q-card-section>
  <q-separator />
  <q-card-actions
    v-if="updatesCards"
    class="print-hide"
  >
    

    <q-btn
      label="Salva"
      color="brand"
      @click="submit"
      icon="save"
      :disable="!changed"
      />
  </q-card-actions>
</q-card>
</template>
<script>
/* Questo componente e' stato generato da:
"# rails generate create_edit_component Oculisti::OculistHistory"
A partire da data_attributes di Oculisti::OculistHistory;
Se lo modificate potra' anche funzionare, ma smettera' quando il componente
verra' rigenerato. Fare riferimento al wiki su github */
import EditFields from 'edit_fields';
import NutriwarnMethods from 'nutriwarn_methods';
import SubmitHistory from 'submit_history';
import ChannelSubscriptionMixin from 'channel_subscription_mixin';
import { mapGetters } from 'vuex';
export default {
  mixins: [EditFields,
           NutriwarnMethods,
           SubmitHistory,
           ChannelSubscriptionMixin],
  props: {
    patientId: {
      type: String,
      required: true
    },
  },
  data: function() {
    return {
      loaded: false,
      changed: false,
      locked: false,
      
      isVisit: false,
      visible: true,
      listUrl: `/patients/${this.patientId}/history.json?history_class=Oculisti::OculistHistory`,
      errors: {},
        value: {
  "terapie_praticate": null,
  "familiarity": null,
  "last_visit": null,
  "consultancy_reason": null,
  "lenti_pl_od": {
    "sfera": null,
    "cilindro": null,
    "asse": null
  },
  "lenti_pl_os": {
    "sfera": null,
    "cilindro": null,
    "asse": null
  },
  "addiction_od": null,
  "addiction_os": null,
  "lenti_pv_od": {
    "sfera": null,
    "cilindro": null,
    "asse": null
  },
  "lenti_pv_os": {
    "sfera": null,
    "cilindro": null,
    "asse": null
  },
  "years": null,
  "lens_ok": null,
  "notes": null
},
        prevValue: {}
    };
  },
  watch: {
    value: {
      handler: function(newV, oldV) {
        this.changed = true;
      },
      deep: true
    }
  },
  methods: {
    visibilityChanged: function(isVisible, entry) {
      this.visible = isVisible;
    },
    toggleLock: function() {
      this.locked = !this.locked;
      if (!this.locked) alert("Ora puoi modificare questa scheda");
    },
    // Makes sense to expose a method to open a Group, that is,
    // a QExpansionItem; I use it to open search results;
    // I still want to open already populated Groups after data loaded,
    // but still no luck
    openGroup: function(gname) {
      // It works also via refs.. but then model is disaligned!
      // const comp = this.$refs[gname];
      // console.log("found? %o", comp);
      // comp.show();
      return this[gname] = true;
    },
    gotoGroup: function(gname) {
      const comp = this.$refs[gname]
      if (comp) {
        comp.$el.scrollIntoView();
        return true;
      } else {
        console.log("group %o not found - not going", gname);
        return false;
      }
    },
    // Give it time to repaint before going
    openGG: function(gname) {
      this.openGroup(gname);
      this.$nextTick(() => { this.gotoGroup(gname) });
    },
    addChanged: function(eye, value) {
  console.log("Add changed, eye: %o, value %o", eye, value);
  console.log("all my value: %o", this.value);
  const srcFieldName = `lenti_pl_${eye}`;
  console.log("src field name is %o val is %o", srcFieldName, this.value[srcFieldName]);
  const destFieldName = `lenti_pv_${eye}`;
  console.log("dst field name is %o", destFieldName);
  const result = parseFloat(this.value[srcFieldName]['sfera']) + parseFloat(value);
  console.log("result sarebbe %o", result);
  if (typeof result === 'number') {
    if (Number.isNaN(result)) {
      console.log('era nan setto vuoto');
      this.value[destFieldName]['sfera'] = '';
      return;
    }
    let fresult = this.formatAddResult(result);
    console.log("Setting value, formatted: %o", fresult);
    this.value[destFieldName]['sfera'] = fresult;
  } else {
    console.log("no res is a %o", typeof(result));
  }
},
formatAddResult: (result) => (result > 0.0) ? `+${result.toFixed(2)}` : result.toFixed(2),

  },
  created: function() {
    // Get data
    this.$http.get(this.listUrl)
      .then(response => {
        this.value = response.data;
        this.loaded = true;
        this.$nextTick(() => { this.changed = false; });
      }, response => {
        this.myAlert("Problemi a caricare la risorsa!");
      });
  },
  computed: {
    ...mapGetters(['doesWorkflow', 'updatesCards'])
  }

}
</script>
<style>
.q-tab-panels {
  background-color: #eeefee;
}
.group-class {
  background-color: #eeeefe;
  border: 1px solid #ddddfe;
}
</style>
