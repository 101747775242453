/* This mixin has the includes for the various editable fields components;
   Makes sense to have them only in one place; Same as display fields,
   TODO: would be handy  to know which ones are used by asking the objects,
   reducing bundle size, and avoiding having to write it here */
export default {
  components: {
    'SdBase': () => import('edit_fields/sd_base'),
    'AncestralField': () => import('edit_fields/ancestral_field'),
    'YesOrNo': () => import('edit_fields/yes_or_no'),
    'IntegerField': () => import('edit_fields/integer_field'),
    'StringField': () => import('edit_fields/string_field'),
    'OneOrTwo': () => import('edit_fields/one_or_two'),
    'YesOrNoDetails': () => import('edit_fields/yes_or_no_details'),
    'YesOrNoPatologica': () => import('edit_fields/yes_or_no_patologica'),
    'YesOrNoPatologicaGravidanza': () => import('edit_fields/yes_or_no_patologica_gravidanza'),
    'YesOrNoCompenso': () => import('edit_fields/yes_or_no_compenso'),
    'Diabete': () => import('edit_fields/diabete'),
    'FemaleInfertility': () => import('edit_fields/female_infertility'),
    'SportStatus': () => import('edit_fields/sport_status'),
    'RegularBeat': () => import('edit_fields/regular_beat'),
    'FirstVisit': () => import('edit_fields/first_visit'),
    'AttachmentArray': () => import('edit_fields/attachment_array'),
    'InformedConsent': () => import('edit_fields/informed_consent'),
    'ComputedField': () => import('edit_fields/computed_field'),
    'ValueArray': () => import('edit_fields/value_array'),
    'VisusFieldDouble': () => import('edit_fields/visus_field_double'),
    'Oftalmometria': () => import('edit_fields/oftalmometria'),
    'Schiascopia': () => import('edit_fields/schiascopia'),
    'BloodPressure': () => import('edit_fields/blood_pressure'),
    'LentiField': () => import('edit_fields/lenti_field'),
    'OpticDisc': () => import('edit_fields/optic_disc'),
    'AnteriorSegment': () => import('edit_fields/anterior_segment'),
    'Diagnosis': () => import('edit_fields/diagnosis'),
    'Retina': () => import('edit_fields/retina'),
    'Iop': () =>  import('edit_fields/iop'),
    'SdLabel': () => import('generic_fields/sd_label'),
    'MementoField': () => import('generic_fields/memento_field')
  }
};
