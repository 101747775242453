/*

Gets mixed to Histories and AntroCards

TODO: Due problemi:
1) Aggiornamento anamnesi non aggiorna, ma spiana e ricrea sul BE
2) Quante if. Mi piacerebbe uniformare il modo in cui vengono trattati
   i due oggetti
*/
export default {
  computed: {
    persisted: function() {
      return !!this.value.id;
    }
  },
  methods: {
    submit: function() {
      if (this.persisted) {
        if (this.isVisit) {
          this.$http.patch(this.url, { antro_card: this.value })
            .then(response => {
              this.errors = {};
              this.$emit('input', response.data);
              this.myNotice('Visita aggiornata');
              this.$nextTick(() => this.changed = false);
            }, this.errorFunc);
        } else { // anamnesi
          this.$http.patch(this.listUrl, { history: this.value })
            .then(response => {
              this.myNotice("Scheda Anamnesi aggiornata");
              this.errors = {};
              // this.value = response.data;
              this.value = { ...response.data, ...this.value };
              this.$nextTick(() => this.changed = false);
            }, this.errorFunc);
        }
      } else { // Nuova scheda/visita
        if (this.isVisit) {
          this.$http.post(this.listUrl, { antro_card: this.value })
            .then(response => {
              this.errors = {};
              this.$emit('input', response.data);
              this.myNotice("Visita creata");
              this.$nextTick(() => this.changed = false);
            }, this.errorFunc);
        } else { // Anamnesi
          this.$http.post(this.listUrl, { history: this.value })
            .then(response => {
              this.errors = {};
              this.value = { ...response.data, ...this.value };
              this.myNotice("Anamnesi creata");
              this.$nextTick(() => this.changed = false);
            }, this.errorFunc);
        }
      }
    },
    submit_with: function(status) {
      this.value.status = status;
      this.submit();
    },
    errorFunc: function(response) {
      const msg = this.isVisit ? "Errore salvando la visita" : "Errore salvando l'anamnesi!";
      this.myAlert(msg);
      this.errors = response.data;
    },
    deleteCard: function() {
      if (!this.persisted) {
        this.$emit('deleted');
        return;
      }
      if (confirm("Sei sicuro/a di voler eliminare la scheda?")) {
        this.$http.delete(this.url)
          .then(() => {
            this.myNotice('Scheda eliminata');
            this.$emit('deleted', this.value.id);
          }, () => {
            this.myAlert("Impossibile eliminare");
          });
      }
    },
    print: function(id) {
      this.$router.push({
        name: 'anamnesi_completa',
        params: {
          patientId: id
        }
      });
    }
  },
  beforeRouteLeave (to, from, next) {
    if (!this.changed) return next();
    if (window.confirm("Ci sono dei dati non salvati. Vuoi veramente abbandonare la pagina?"))
      return next();
    next(false);
  }
};
