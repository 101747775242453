<template>
<q-card
  v-if="loaded"
  :id="`general_history_${value.id}`"
  class="q-ma-md"
  :class="{ changed: changed, locked: locked }"
  v-observe-visibility="{
    callback: visibilityChanged,
    intersection: {
      rootMargin: '10000px 0px 10000px 0px'
    }
  }"
  >
  <q-card-section v-if="visible">
    <div class="row">
  <div class="col-xs-12 col-sm-6">
  <diabete
    v-model="value.diabetes"
    :prevValue="prevValue.diabetes"
    :disable="locked"
    :nutriwarn="true"
    label="Diabete"
    fieldName="diabetes" />
</div>
<div class="col-xs-12 col-sm-6">
  <yes-or-no
    v-model="value.cardiopaty"
    :prevValue="prevValue.cardiopaty"
    :disable="locked"
    label="Cardiopatie"
    fieldName="cardiopaty" />
</div>

</div>
<div class="row">
  <div class="col-xs-12 col-sm-6">
  <integer-field
    class="inset"
    v-model="value.diabetes_hbgl"
    :prevValue="prevValue.diabetes_hbgl"
    :disable="locked"
    label="HBGL"
    fieldName="diabetes_hbgl"
    v-if="is_prop_true('diabetes')"
    unit="%"
    :min="0"
    :max="100" />
</div>
<div class="col-xs-12 col-sm-6">
  <string-field
    class="inset"
    v-model="value.cardiopaty_notes"
    :prevValue="prevValue.cardiopaty_notes"
    :disable="locked"
    label="Note"
    fieldName="cardiopaty_notes"
    v-if="is_prop_true('cardiopaty')" />
</div>

</div>
<div class="row">
  <div class="col-xs-12 col-sm-6" /><div class="col-xs-12 col-sm-6">
  <string-field
    class="inset"
    v-model="value.cardiopaty_therapy"
    :prevValue="prevValue.cardiopaty_therapy"
    :disable="locked"
    label="Terapie"
    fieldName="cardiopaty_therapy"
    v-if="is_prop_true('cardiopaty')" />
</div>

</div>
<div class="row">
  <div class="col-xs-12 col-sm-6">
  <yes-or-no-compenso
    v-model="value.hypertension"
    :prevValue="prevValue.hypertension"
    :disable="locked"
    :nutriwarn="true"
    label="Ipertensione"
    fieldName="hypertension" />
</div>
<div class="col-xs-12 col-sm-6">
  <yes-or-no
    v-model="value.neuro"
    :prevValue="prevValue.neuro"
    :disable="locked"
    label="Malattie Neurologiche"
    fieldName="neuro" />
</div>

</div>
<div class="row">
  <div class="col-xs-12 col-sm-6">
  <string-field
    class="inset"
    v-model="value.hypertension_notes"
    :prevValue="prevValue.hypertension_notes"
    :disable="locked"
    label="Note"
    fieldName="hypertension_notes"
    v-if="is_prop_true('hypertension')"
    subtype="textarea" />
</div>
<div class="col-xs-12 col-sm-6">
  <string-field
    class="inset"
    v-model="value.neuro_notes"
    :prevValue="prevValue.neuro_notes"
    :disable="locked"
    label="Note"
    fieldName="neuro_notes"
    v-if="is_prop_true('neuro')" />
</div>

</div>
<div class="row">
  <div class="col-xs-12 col-sm-6" /><div class="col-xs-12 col-sm-6">
  <string-field
    class="inset"
    v-model="value.neuro_therapy"
    :prevValue="prevValue.neuro_therapy"
    :disable="locked"
    label="Terapie"
    fieldName="neuro_therapy"
    v-if="is_prop_true('neuro')" />
</div>

</div>
<div class="row">
  <div class="col-xs-12 col-sm-6">
  <yes-or-no
    v-model="value.astma"
    :prevValue="prevValue.astma"
    :disable="locked"
    label="Insufficienza Respiratoria"
    fieldName="astma" />
</div>
<div class="col-xs-12 col-sm-6">
  <yes-or-no
    v-model="value.allergy"
    :prevValue="prevValue.allergy"
    :disable="locked"
    label="Allergie"
    fieldName="allergy" />
</div>

</div>
<div class="row">
  <div class="col-xs-12 col-sm-6" /><div class="col-xs-12 col-sm-6">
  <string-field
    class="inset"
    v-model="value.allergy_notes"
    :prevValue="prevValue.allergy_notes"
    :disable="locked"
    label="Note"
    fieldName="allergy_notes"
    v-if="is_prop_true('allergy')" />
</div>

</div>
<div class="row">
  <div class="col-xs-12 col-sm-6" /><div class="col-xs-12 col-sm-6">
  <string-field
    class="inset"
    v-model="value.allergy_therapy"
    :prevValue="prevValue.allergy_therapy"
    :disable="locked"
    label="Terapie"
    fieldName="allergy_therapy"
    v-if="is_prop_true('allergy')" />
</div>

</div>
<div class="row">
  <div class="col-xs-12 col-sm-12">
  <string-field
    v-model="value.other"
    :prevValue="prevValue.other"
    :disable="locked"
    label="Altro"
    fieldName="other"
    subtype="textarea" />
</div>

</div>

  </q-card-section>
  <q-separator />
  <q-card-actions
    v-if="updatesCards"
    class="print-hide"
  >
    

    <q-btn
      label="Salva"
      color="brand"
      @click="submit"
      icon="save"
      :disable="!changed"
      />
  </q-card-actions>
</q-card>
</template>
<script>
/* Questo componente e' stato generato da:
"# rails generate create_edit_component Oculisti::GeneralHistory"
A partire da data_attributes di Oculisti::GeneralHistory;
Se lo modificate potra' anche funzionare, ma smettera' quando il componente
verra' rigenerato. Fare riferimento al wiki su github */
import EditFields from 'edit_fields';
import NutriwarnMethods from 'nutriwarn_methods';
import SubmitHistory from 'submit_history';
import ChannelSubscriptionMixin from 'channel_subscription_mixin';
import { mapGetters } from 'vuex';
export default {
  mixins: [EditFields,
           NutriwarnMethods,
           SubmitHistory,
           ChannelSubscriptionMixin],
  props: {
    patientId: {
      type: String,
      required: true
    },
  },
  data: function() {
    return {
      loaded: false,
      changed: false,
      locked: false,
      
      isVisit: false,
      visible: true,
      listUrl: `/patients/${this.patientId}/history.json?history_class=Oculisti::GeneralHistory`,
      errors: {},
        value: {
  "diabetes": {
    "isSet": null,
    "year": "",
    "buonCompenso": false,
    "insulina": false
  },
  "cardiopaty": null,
  "diabetes_hbgl": null,
  "cardiopaty_notes": null,
  "cardiopaty_therapy": null,
  "hypertension": {
    "isSet": null,
    "year": "",
    "buonCompenso": false
  },
  "neuro": null,
  "hypertension_notes": null,
  "neuro_notes": null,
  "neuro_therapy": null,
  "astma": null,
  "allergy": null,
  "allergy_notes": null,
  "allergy_therapy": null,
  "other": null
},
        prevValue: {}
    };
  },
  watch: {
    value: {
      handler: function(newV, oldV) {
        this.changed = true;
      },
      deep: true
    }
  },
  methods: {
    visibilityChanged: function(isVisible, entry) {
      this.visible = isVisible;
    },
    toggleLock: function() {
      this.locked = !this.locked;
      if (!this.locked) alert("Ora puoi modificare questa scheda");
    },
    // Makes sense to expose a method to open a Group, that is,
    // a QExpansionItem; I use it to open search results;
    // I still want to open already populated Groups after data loaded,
    // but still no luck
    openGroup: function(gname) {
      // It works also via refs.. but then model is disaligned!
      // const comp = this.$refs[gname];
      // console.log("found? %o", comp);
      // comp.show();
      return this[gname] = true;
    },
    gotoGroup: function(gname) {
      const comp = this.$refs[gname]
      if (comp) {
        comp.$el.scrollIntoView();
        return true;
      } else {
        console.log("group %o not found - not going", gname);
        return false;
      }
    },
    // Give it time to repaint before going
    openGG: function(gname) {
      this.openGroup(gname);
      this.$nextTick(() => { this.gotoGroup(gname) });
    },
    
  },
  created: function() {
    // Get data
    this.$http.get(this.listUrl)
      .then(response => {
        this.value = response.data;
        this.loaded = true;
        this.$nextTick(() => { this.changed = false; });
      }, response => {
        this.myAlert("Problemi a caricare la risorsa!");
      });
  },
  computed: {
    ...mapGetters(['doesWorkflow', 'updatesCards'])
  }

}
</script>
<style>
.q-tab-panels {
  background-color: #eeefee;
}
.group-class {
  background-color: #eeeefe;
  border: 1px solid #ddddfe;
}
</style>
