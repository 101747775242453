// Mixin che contiene sia i metodi per nutriwarn, sia quelli per capire
// se c'e' un valore (e quindi etichetta piu' marcata) o meno
// (etichetta grigetta).
// Contiene anche i computed tipo bmi e whr, che non e' ottimale.
// Nota che dipende dal fatto che text-lighty e' come q-field__label ma piu' chiaro
export default {
  computed: {
    // L'etichetta e' bold se il campo e' compilato.
    hasArrayValue: function() {
      return this.value && this.value.length > 0;
    },
    hasScalarValue: function() {
      return !!this.value;
    },
    hasNonNullValue: function() {
      return !!this.value;
    },
    hasIsSetValue: function() {
      return this.value.isSet;
    },
    // Default per la "devo fare nutriwarn?"
    shouldHighlight: function() {
      if (!this.nutriwarn) return false;
      if (this.nutriwarn === true) return this.defaultHighlightFun();
      return this.performTest(this.nutriwarn);
    },
    bmi: function() {
      if (!this.value.weight ||
          !this.value.height)
        return 'N/A';
      else {
        var hsq = (this.value.height * 1.0) / 100.0;
        hsq = hsq * hsq;
        var pren = (this.value.weight * 1.0) / hsq;
        return Math.round(pren * 100 + Number.EPSILON) / 100;
      }
    },
    whr: function() {
      if (!this.value.waist_circumference ||
          !this.value.hip_circumference)
        return 'N/A';
      else {
        var hsq  = (this.value.waist_circumference * 1.0);
        var pren = (hsq / (this.value.hip_circumference * 1.0));
        return Math.round(pren * 100 + Number.EPSILON) / 100;
      }
    },
    isFirstVisit: function() {
      return (this.value.is_first_visit === 'true');
    },
    isControllo: function() {
      return (this.value.is_first_visit != 'true');
    }
  },
  methods: {
    // Questo dispatcha su uno qualsiasi dei metodi; Usato da
    // shouldHighlight per nutriwarn
    performTest: function(meth) {
      //console.log("invocata performtes con qualche meth");
      return meth(this.value);
    },
    // Chiamata nelle v-if delle dipendenze dai field dei model.
    // il check preciso dipenderebbe dal componente...
    // ma per ora forse me la cavo cosi'
    is_prop_true: function(property) {
      // console.log("is it true %o", property);
      // Per quelli tipo hypertension_and_altri, non sgamabili altrimenti
      if (typeof this[property] === 'function') {
        const ss = this[property];
        // console.log("Caso speciale - chiesto %o ha %o", property, ss);
        return ss();
      }
      const p = this.value[property];
      // console.log("Nothing special when asking %o, to %o", property, p);
      if (!p) return false;
      if (p == 'false') return false;
      if (p == 'true') return true;
      if (typeof(p) === 'string' && p) return true;
      if (!p.isSet) return false;
      if (p.isSet == 'false') return false;
      // console.log("Alla fine torno true.. sara' cosi'?");
      return true;
    },
    hypertension_and_altri: function() {
      return this.is_prop_true('hypertension') &&
        this.value.hypertension_treatment.indexOf('Altri') >= 0;
    },
    oneIsRed: value => value === '1' || value === '2',
    noIsRed: value => value === 'false',
    // Questi sono specifici di Dietologi
    isDiuretic: value => value.indexOf('Diuretici') >= 0,
    lt1Lt: value => value === '<1Lt',
    isMolto: value => value === 'Molto',
    bmiWarn: value => value > 30 || value < 18,
    minPressWarn: value => (value &&
                            (value.min < 50 ||
                             value.min > 95)),
    maxPressWarn: value => (value &&
                            (value.max < 90 ||
                             value.max > 140)),
    beatWarn: value => (value &&
                        (value < 55 ||
                         value > 95)),
    muchCellulite: value => value > 2,
    isAWimp: value => value == 1,
    isFlaccid: value => value == 1,
  }
};
