export default {
  channels: {
    UploadNotificationsChannel: {
      rejected(what) {
        console.error("Rejected: %o", what);
      },
      received(data) {
        console.log("channel_subscription_mixin received: %o", data);
        const name = data.name;
        if (!name) {
          console.error("Got no name in data: %o", data);
          return;
        }
        // Get those specific attachments from server
        this.$http.get(
          `/antro_cards/${this.value.id}/attachments/`,
          { params: { name: name } }
        ).then(response => {
          this.value[name] = response.data;
        }, response => {
          // We may get 404 if we are notified about an AntroCard being
          // deleted. In this case we save beQuiet on response to prevent
          // generic alert notification from showing up
          if (response.status == 404) {
            response.beQuiet = true;
            return;
          }
          this.errors = response.errors;
        });
      }
    }
  },
  // le history... quando le monto non hanno (ancora) value.id,
  // viene caricato dopo
  mounted() {
    if (!this.value.id) return;

    // console.log("ho value.id, sono AntroCard");
    this.$cable.subscribe({
      channel: 'UploadNotificationsChannel',
      antro_card_id: this.value.id,
      from: 'vue'
    });
  }
};
