<template>
  <router-view />
</template>
<script>
import { mapState } from 'vuex';
export default {
  data: function() {
    return {
      // Non usati a dire il vero
      errors: {}
    };
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  mounted: function() {
    this.loadUser();
    this.subscribeAppDeployed();
  },
  channels: {
    app_deployed_channel: {
      received(data) {
        console.log("app_deployed rcvd %o", data);
        this.$q.notify({
          position: 'top',
          html: true,
          message: "Applicazione modificata sul server;<br>Ricarica la pagina quanto prima.. ma salva la scheda se hai modificato qualcosa; Messaggio: " +
            JSON.stringify(data),
          color: 'info',
          multiline: true,
          timeout: 0,
          actions: [
            {
              label: 'Chiudi',
              color: 'white'
            },
            {
              label: 'Ricarica',
              color: 'white',
              handler: () => { window.location.reload() ; return false; }
            }
          ]
        });
      }
    }
  },

  methods: {
    loadUser: function() {
      // console.log("runno loadUser, sto a fa la get");
      this.$http.get('/users/me.json')
        .then(response => {
          // console.log("users/me returned from server with 200, setting stuff in proto: %o", response.data);
          this.loggedIn(response.data);
        }, response => {
          if (response.status != 401) {
            console.log("users/me returned from server with another error %o",response);
            this.otherErrors(response);
          }
        });
    },
    otherErrors: function(response) {
      this.errors = response.errors || response;
      this.myAlert("Qualche errore strano: " + JSON.stringify(this.errors));
    },
    loggedIn: function(user) {
      // console.log("loggedIn committa setUser, ma la route al momento: %o, la url: %o",
      //              this.$router.currentRoute,
      //              window.location.href);
      this.$store.commit('setUser', user);
      // console.log("Mentre la route? %o", this.$router.currentRoute);
      if (window.location.href.indexOf('/app/') === -1) {
        console.log("Sto loggato ma non dentro app, pusho prenotazioni");
        this.$router.push('/app/prenotazioni');
      }
    },
    subscribeAppDeployed: function() {
      this.$cable.subscribe(
        {
          channel: 'AppDeployedChannel'
        },
        'app_deployed_channel'
      );
    }
  }
};
</script>
